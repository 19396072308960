
import { Component, Prop, Vue } from 'vue-property-decorator'
import OAuth from 'oauth-1.0a'
import CryptoJS from 'crypto-js'

@Component
export default class IconBtn extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private type!: string

  @Prop({
    type: String,
    required: false
  })
  private text!: string

  @Prop({
    type: String,
    required: false
  })
  private link!: string

  @Prop({
    type: Boolean,
    required: false
  })
  private textBold!: boolean

  @Prop({
    type: String,
    required: false
  })
  private classname!: string

  private imageSrc: string = ''

  private iconType(): void {
    switch (this.type) {
      case 'talentbook':
        this.imageSrc = require('~/assets/img/svg/icon_talentbook.svg')
        break
      case 'email':
        this.imageSrc = require('~/assets/img/svg/icon_email.svg')
        break
      case 'twitter':
        this.imageSrc = require('~/assets/img/svg/icon_x.svg')
        break
      case 'google':
        this.imageSrc = require('~/assets/img/svg/icon_google.svg')
        break
    }
  }

  private signupButton(): void {
    this.$router.push('/signup')
    this.$store.dispatch('modal/changeNonMemberModalState', false)
  }

  private get signInGoogleUrl(): string {
    return process.env.MEMBER_GOOGLE_SIGNUP_REDIRECT
  }

  private get googleClientID(): string {
    return process.env.MEMBER_GOOGLE_CLIENT_ID
  }

  private mounted(): void {
    this.iconType()
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`
    )
    const gsiClientScript = document.createElement('script')
    gsiClientScript.setAttribute(
      'src',
      'https://accounts.google.com/gsi/client'
    )
    document.head.appendChild(gsiClientScript)
  }

  private async twitterLogin(): Promise<void> {
    const oauth = new OAuth({
      consumer: {
        key: process.env.MEMBER_TWITTER_API_KEY,
        secret: process.env.MEMBER_TWITTER_API_SECRET
      },
      signature_method: 'HMAC-SHA1',
      hash_function(baseString, key) {
        return CryptoJS.HmacSHA1(baseString, key).toString(CryptoJS.enc.Base64)
      }
    })
    const twitterRequestTokenUrl = '/twitter/oauth/request_token'
    const requestData = {
      url: 'https://api.twitter.com/oauth/request_token',
      method: 'POST',
      data: {
        oauth_callback: process.env.MEMBER_TWITTER_OAUTH_CALLBACK
      }
    }
    const oauthData = oauth.authorize(requestData)
    const config = {
      headers: oauth.toHeader(oauthData)
    }
    // login後に元ページにリダイレクトする為のflag(リダイレクト自体はmiddleware,storage.setはNonMemberModal)
    // twitterはログインキャンセルが可能なため、twitterFlagをset
    localStorage.setItem('twitterLogin', JSON.stringify(true))
    try {
      await this.$axios
        .$post(twitterRequestTokenUrl, requestData, config)
        .then((rs: any) => {
          location.href = `https://api.twitter.com/oauth/authorize?${rs}`
        })
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    }
  }
}
